<template>
	<div class='mb-3 alert alert-success fade show'>
		{{message}}
	</div>
</template>

<script>
export default {
	name: "Message",
	props: {
		title: String,
		message: String
	}
};
</script>